import React from "react";

const Section = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({className, children, ...props}, ref) => {
    return (
      <section ref={ref} className={`px-4 sm:px-8 ${className}`} {...props}>
        {children}
      </section>
    );
  },
);

Section.displayName = "Section";

export default React.memo(Section);
