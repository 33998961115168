"use client";

import {useTwMediaQuery} from "../../../hooks/useTwMediaQuery";
import {Breakpoint} from "../../../constants/breakpoint";
import Button, {ButtonSize, ButtonVariant} from "@components/Button";
import {PracticeId} from "../../../constants/practiceIds";
import {trackNavLinkClick} from "@components/Nav/utils";
import {getLoginRedirectUrl} from "../../../utils/getRedirectUrl";
import Icon from "@components/Icon";
import React, {useState} from "react";
import {useTranslation} from "@i18n/client";
import {v4} from "uuid";
import {usePathname, useSearchParams} from "next/navigation";
import {buildBookingLink} from "@page-containers/booking/utils/bookingQuery";

const LoginButton = ({
  activeStyle,
  bookingHref,
  transitionClass,
}: {
  activeStyle: {buttonBackground: string; backgroundColor: string};
  /**
   * If defined, this will be the href used for the "book an appointment" link
   * in the nav bar.
   *
   * This can be set by pages with enough context to skip booking steps.
   *
   * If undefined, a default link to the start of web scheduling will be used.
   */
  bookingHref?: string;
  transitionClass: string;
}) => {
  const i18n = useTranslation();
  const isMd = !useTwMediaQuery(Breakpoint.MD);
  const [flowId] = useState(v4());

  const pathOrNull = usePathname();
  const searchParamsOrNull = useSearchParams();

  const href =
    bookingHref ??
    buildBookingLink({
      flowId,
      practiceId: PracticeId.CARBON,
      originUrl: encodeURIComponent(`${pathOrNull}?${searchParamsOrNull}`),
    });

  return (
    <>
      <li>
        <Button
          variant={ButtonVariant.GREEN}
          size={ButtonSize.SM}
          href={href}
          onClick={() =>
            trackNavLinkClick(
              "Account",
              "Menu",
              i18n.t("Book an Appointment"),
              i18n.t("Book an Appointment"),
            )
          }
        >
          {isMd ? i18n.t("Book") : i18n.t("Book an Appointment")}
        </Button>
      </li>
      <li className="hidden sm:block">
        <Button
          variant={ButtonVariant.NAKED}
          href={getLoginRedirectUrl()}
          onClick={() => trackNavLinkClick("Account", "Menu", "My Account", "My Account")}
        >
          <Icon
            icon="person-upper-body"
            className={`text-lg leading-none ${
              activeStyle.backgroundColor === "transparent" ? "text-white" : "text-gray-1000"
            } ${transitionClass}`}
          />
        </Button>
      </li>
    </>
  );
};

export default React.memo(LoginButton);
