import React, {useState} from "react";
import {onKeyHandler} from "src/useEscape";

import {isBrowser} from "src/utils/isBrowser";

export type SkipLinkItem = {
  targetId: string;
  text: string;
};

type Props = {
  item: SkipLinkItem;
  showOnFocus?: boolean;
};

const SkipLink: React.FC<Props> = ({item: {targetId, text}, showOnFocus}) => {
  const [focused, setFocused] = useState(false);
  const toggleFocus = (boolean?: boolean) => () => {
    setFocused(typeof boolean === "boolean" ? boolean : !focused);
  };

  const focusTarget = isBrowser() && document.getElementById(targetId);

  return (
    <a
      href={`#${targetId}`}
      {...(focusTarget
        ? {
            onKeyDown: onKeyHandler("Enter", () => {
              focusTarget.focus();
            }),
            onClick: () => {
              focusTarget.focus();
            },
          }
        : {})}
      className={`ph3 pv2 gray800 bg-white br5 font-ib fs14 lh15 wsnw bs2 focus-bsDarkBlue3-hug pos-r ${
        showOnFocus ? "showOnFocus" : ""
      }`}
      onFocus={toggleFocus(true)}
      onBlur={toggleFocus(false)}
    >
      {text}
    </a>
  );
};

export default SkipLink;
