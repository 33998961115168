/* eslint-disable react/no-invalid-html-attribute */
import {colors} from "@c10h/colors";
import CSS from "csstype";
import Head from "next/head";
import {useRouter} from "next/router";
import Script from "next/script";
import {useTranslation} from "@i18n/client";
import React, {memo, useEffect, useState} from "react";
import {languages} from "src/languages";
import {NEXT_PUBLIC_TALKDESK_FLOW_ID} from "src/publicEnv";
import {getCanonicalLink, getLinkWithLocale} from "src/utils/getLink";

import {bannerSpacer} from "../../constants/dynamicBanner";
import {highZIndexMap} from "../../utils/zIndexMap";
import {Pathname, v5Pages, v5PagesTitles, widgetAllowedAsPaths} from "../_common/_constants";
import {LazyComp} from "../_common/LazyComp";
import Footer from "./Footer/Footer";
import {
  EXCLUDED_PAGES,
  LocationExplorerContainer,
} from "./LocationExplorer/LocationExplorerContainer";
import Nav from "./Nav/Nav";
import {NavTheme} from "./Nav/types";
import {SkipLinkItem} from "./SkipLink";
import SkipLinkBar from "./SkipLinkBar";
import {getHostWithProtocol} from "../../utils/urls";
import {isProd} from "src/utils/isProd";
import {useTypedSelector} from "src/store";

// TODO: fix these types
type LayoutProps = {
  /**
   * If defined, this will be the href used for the "book an appointment" link
   * in the nav bar.
   *
   * This can be set by pages with enough context to skip booking steps.
   *
   * If undefined, a default link to the start of web scheduling will be used.
   */
  bookingHref?: string;
  children?: any;
  style?: CSS.Properties;
  pageTitle?: string;
  pageDescription?: string | null;
  className?: string;
  wrapClasses?: string;
  pageImage?: string;
  absolutePageImage?: string;
  pageImageExt?: "jpg" | "gif";
  noHeader?: boolean;
  noFooter?: boolean;
  navTheme?: NavTheme;
  navStartTransparent?: boolean;
  noIndex?: boolean;
  blockChatWidget?: boolean;
  ogAlt?: string;
  schemas?: string[];
  skipLinks?: SkipLinkItem[];
  minimalFooter?: boolean;
};

const defaultPageImage = "default-page-image";

const Layout = ({
  bookingHref,
  children,
  style,
  pageTitle,
  pageDescription,
  className = "",
  wrapClasses = "ovf-x-hidden",
  pageImage = defaultPageImage,
  absolutePageImage,
  noHeader,
  noFooter,
  navTheme,
  navStartTransparent,
  noIndex,
  blockChatWidget,
  ogAlt,
  schemas,
  pageImageExt = "jpg",
  skipLinks = [],
  minimalFooter,
}: LayoutProps) => {
  const {asPath, pathname} = useRouter();
  const config = useTypedSelector(s => s.config);

  const i18n = useTranslation();
  const {locations} = useTypedSelector(({config}) => config);
  const ogAltText = ogAlt || i18n.t("Carbon Health is making good health accessible to everyone");

  const [ready, setRready] = useState(false);
  const rootUrl = getHostWithProtocol();

  const bannerImage =
    absolutePageImage || `${rootUrl}/static/img/_common/og_image/${pageImage}.${pageImageExt}`;
  const canonical = getCanonicalLink(asPath);
  const title = pageTitle || i18n.t("Carbon Health | Modern Primary & Urgent Care");
  const desc =
    (pageDescription || "").replace(/['"]+/g, "") ||
    i18n.t(
      "Carbon Health Provides Smart, hassle-free Primary & Urgent Care. Book same day Adult & Pediatric appointments instantly.",
    );
  const scssColorVarMap = Object.keys(colors)
    .sort()
    .reduce(
      (acc, next) => ({
        ...acc,
        // @ts-expect-error TS7053: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'typeof import("/Users/goksel/Sites/carbon-website-next/node_modules/@c10h/colors/types/palette")'.
        [`--${next}`]: colors[next],
      }),
      {},
    );

  const breadCrumb = {
    // @ts-expect-error TS7053: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ "/": string; "/primary-care": string; "/urgent-care": string; "/locations": string; "/locations2": string; "/locations/[slug]": string; "/insurance-pricing": string; "/about-us": string; "/partners": string; ... 18 more ...; "/uc-hastings": string; }'.
    name: v5PagesTitles[pathname] || pathname.replace("/", "") || "",
    item: rootUrl + asPath,
  };

  const cleanedAsPath = asPath.replace("/", "");
  const isWidgetAllowedAsPaths =
    widgetAllowedAsPaths.includes(cleanedAsPath) || pathname === v5Pages.clinicDetails;
  const shouldNotIndex = noIndex || !isProd;

  useEffect(() => {
    setRready(true);
  }, []);

  const a11ySkipLinks = [
    {targetId: "main", text: i18n.t("Skip to Main Content")},
    ...skipLinks,
  ].distinctBy("targetId");

  return (
    <div
      id="layout"
      className={`w100p ma bg-white wspl ${className}`}
      style={{
        ...style,
        maxWidth: "118.75rem",
        "--defaultColor": colors.mint,
        "--pcColor": colors.blue,
        "--ucColor": colors.yellow,
        ...scssColorVarMap,
      }}
    >
      <Head>
        <title id="title">{title}</title>
        {/* Note: when making changes to meta tags please also update src/app/layout.tsx metadata */}
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=yes, viewport-fit=cover"
        />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="apple-itunes-app" content="app-id=1152807572" />
        <meta name="google-play-app" content="app-id=com.carbonhealth.patient.prod" />

        <meta property="og:title" content={title} />
        <meta property="og:description" content={desc} />
        <meta property="og:url" content={canonical} />
        <meta property="og:image" content={bannerImage} />
        <meta property="og:image:alt" content={ogAltText} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />

        <meta property="og:site_name" content="Carbon Health" />
        <meta name="twitter:image:alt" content={ogAltText} />

        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={desc} />
        <meta name="twitter:image" content={bannerImage} />

        <meta property="fb:app_id" content="175947640371537" />
        <meta name="twitter:site" content="@CarbonHealth" />

        <link rel="image_src" href={bannerImage} />

        <meta name="description" content={desc} />
        <link rel="canonical" href={canonical} />

        {languages.map(({code}) => (
          <link key={code} rel="alternate" hrefLang={code} href={getLinkWithLocale(asPath, code)} />
        ))}

        {shouldNotIndex && <meta name="robots" content="noindex" />}

        {schemas &&
          schemas.map((schema, i) => (
            <script
              key={`schema-${i}`}
              type="application/ld+json"
              dangerouslySetInnerHTML={{__html: schema}}
            />
          ))}

        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "https://schema.org",
              "@type": "BreadcrumbList",
              itemListElement: [
                {
                  "@context": "https://schema.org",
                  "@type": "ListItem",
                  position: 1,
                  item: {
                    "@id": breadCrumb.item,
                    name: breadCrumb.name,
                  },
                },
              ],
            }),
          }}
        />

        <meta name="thumbnail" content={bannerImage} />

        <noscript
          dangerouslySetInnerHTML={{
            __html: `
              <!--
            <PageMap>
              <DataObject type="thumbnail">
                <Attribute name="src" value="${bannerImage}"/>
                <Attribute name="width" value="120"/>
                <Attribute name="height" value="63"/>
              </DataObject>
            </PageMap>
          -->
              `,
          }}
        />
      </Head>
      <div className="grid min-h-screen relative" style={{gridTemplateRows: "auto 1fr auto"}}>
        <header style={{zIndex: highZIndexMap.NAV_BAR}}>
          <SkipLinkBar skipLinks={a11ySkipLinks} />
          {!noHeader && (
            <Nav
              bookingHref={bookingHref}
              theme={navTheme}
              startTransparent={navStartTransparent}
              locations={config.locations}
            />
          )}
        </header>

        <main
          className={wrapClasses + " relative"}
          id="main"
          style={{
            ...(noHeader ? {paddingTop: 0} : {}),
            maxWidth: "min(118.75rem, 100vw)",
          }}
        >
          <div id={bannerSpacer} />
          {children}
        </main>

        {!EXCLUDED_PAGES.includes(pathname as Pathname) && (
          <LazyComp>
            <LocationExplorerContainer locations={locations} />
          </LazyComp>
        )}

        {!noFooter && <Footer minimal={minimalFooter} />}
      </div>

      {ready && isWidgetAllowedAsPaths && !blockChatWidget && (
        <Script
          src={`/static/js/talkdesk.js?flowId=${NEXT_PUBLIC_TALKDESK_FLOW_ID}`}
          strategy="lazyOnload"
          type="text/javascript"
          id="tdChatWidgetScript"
        />
      )}
    </div>
  );
};

export default memo(Layout);
