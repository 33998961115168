import {colors} from "@c10h/colors";
import Circle from "@components/Circle";
import NextImage from "@components/NextImage";
import {Btn} from "@components/V5Comp";
import Regions from "@data/localRegions.json";
import {useTranslation} from "@i18n/client";
import React, {useMemo} from "react";
import {v4} from "uuid";

import {SpecialtyId} from "../../../constants/specialtyIds";
import {QueryStatus} from "../../../hooks/useQueryController";
import {useReadableSlotTime} from "../../../hooks/useReadableSlotTime";
import {useSoonestVirtualSlotInState} from "../../../hooks/useSoonestVirtualSlot";
import {analytics} from "../../../utils/analytics";
import {getSchedulingRedirectUrl} from "../../../utils/getRedirectUrl";
import {getStateCodeFromRegionSlug} from "../../../utils/stateUtils";
import Glimmer from "../Glimmer";
import {useUserSelectedLocation} from "../../../utils/browser-storage/userSelectedLocation";

const Loading = React.memo(function Loading() {
  return (
    <div className="dg gg2 gafr w100p">
      <Glimmer height={200} />
      <Glimmer height={30} />
      <Glimmer height={22} width="30%" />
    </div>
  );
});

const VirtualLocations: React.FC = () => {
  const {regionSlug: selectedRegion} = useUserSelectedLocation();
  const i18n = useTranslation();

  const flowId = useMemo(() => v4(), []);
  const specialtyId = useMemo(() => [SpecialtyId.VIRTUAL_URGENT_CARE], []);
  const regionName = useMemo(
    () => Regions.find(({slug}) => slug === selectedRegion)?.name,
    [selectedRegion],
  );
  const soonestVirtualSlotQuery = useSoonestVirtualSlotInState(specialtyId);
  const slot = soonestVirtualSlotQuery?.data;

  const onClick = () => {
    analytics.post({
      category: analytics.category.APPOINTMENT_SCHEDULING,
      action: analytics.action.CLICKED,
      label: analytics.label.APPOINTMENT_BOOKING_LOCATION_EXPLORER,
      value: "Book Appointment",
      extraData: {
        flowId,
        specialtyId,
        isVirtual: true,
        preselectedState,
      },
    });
  };

  const time = useReadableSlotTime(slot?.time);
  const preselectedState = getStateCodeFromRegionSlug(selectedRegion);
  const href = getSchedulingRedirectUrl({
    flowId,
    virtual: true,
    specialtyId,
    // @ts-expect-error TS2322: Type 'string | null' is not assignable to type 'string | undefined'.
    preselectedState,
  });

  if (soonestVirtualSlotQuery.status === QueryStatus.LOADING)
    return (
      <div className="p6 p4-sm maxw30">
        <Loading />
      </div>
    );

  if (!slot) return null;

  return (
    <div className="df fdc mb8-md maxw30">
      <a href={href} onClick={onClick} target="_blank" rel="noopener noreferrer">
        <div className="pos-r mb4" style={{height: 275}}>
          <NextImage
            className="br1"
            objectPosition="50% 50%"
            layout="fill"
            src="/dad-with-daughter"
            alt={i18n.t("A happy father with his daughter during a virtual appointment")}
          />
        </div>
        <div className="fs14 zIndex1 bg-transparent">
          <div className="df fdc jcsb pos-r hide-scroll-bars">
            <h3
              className="font-csb-f mb3 fw5-f mb1 fs18 minw18 minw0-xs font-isb gray800"
              data-cy="location-name"
            >
              {i18n.t("{{regionName}}: Virtual care only", {regionName})}
            </h3>
            <div className="df fdc ovf-x-a">
              <ul className="mb2 dg gg2 gafr">
                <li>
                  <div className="font-ir gray600 df aic wsnw fw ml2">
                    <div className="df aic pos-r">
                      <Circle
                        diameter={5}
                        color={colors.darkGreen}
                        className="mr1 pos-a"
                        style={{left: -8}}
                      />
                      <span className="font-ib gray800 wsnw">{i18n.t("Virtual Urgent Care")}</span>
                      <span className="fs10 mh1" aria-hidden>
                        •
                      </span>
                    </div>
                    <div className="df aic">
                      <span className="wsnw">{i18n.t("Next: {{time}}", {time})}</span>
                    </div>
                  </div>
                </li>
              </ul>
              <Btn
                fontSize={0.75}
                className="mt2 mb1 focus-bsDarkBlue3-hug"
                bg={colors.darkGreen}
                onClick={onClick}
                aria-label={i18n.t("Book virtual urgent care appointment")}
                type="link"
                faux
              >
                {i18n.t("Book Appointment")}
              </Btn>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};

export default React.memo(VirtualLocations);
