"use client";

import {LocationExplorer, LocationForLocationExplorer} from "@components/LocationExplorer/index";
import {useTranslation} from "@i18n/client";
import React, {useMemo} from "react";
import {RegionSlug} from "src/store/types";

import {isSaasLocation} from "../../../constants/locations";
import {Pathname, v5Pages} from "../../_common/_constants";
import {useUserSelectedLocation} from "../../../utils/browser-storage/userSelectedLocation";
import useNextPagesPaths from "src/hooks/app-pages-transition/useNextPagesPaths";
import {useSearchParams} from "next/navigation";
import {generateRegionData} from "src/utils/regions/regionUtils";

export type LocationForLocationExplorerContainer = LocationForLocationExplorer & {
  practiceId: string;
  deactivatedAt?: number;
  region: {
    name: string;
    latitude: number;
    longitude: number;
  };
};

type Props = {
  locations: LocationForLocationExplorerContainer[];
};

export const EXCLUDED_PAGES: Pathname[] = [
  // Location Index
  v5Pages.locations,

  // Partner Pages
  v5Pages.covid19TravelClearance,
  v5Pages.partners,
  v5Pages.collegesOfFenway,

  // Care Discovery
  v5Pages.getCareSlug,
  v5Pages.testpage,

  v5Pages.careers,
];

export const LocationExplorerContainer: React.FC<Props> = ({locations}) => {
  const i18n = useTranslation();
  const {regionSlug: selectedRegion} = useUserSelectedLocation();

  const {pathname} = useNextPagesPaths();
  const searchParams = useSearchParams();
  const querySlug = searchParams?.get("slug");
  const isLocationPage = pathname === v5Pages.clinicDetails;

  let currentLocationId;
  let initialRegion = selectedRegion;
  let isSlotTimeVisible = true;
  let headerText = i18n.t("Explore our locations");
  const currentLocation = locations.find(({slug}) => slug === querySlug);

  if (isLocationPage) {
    if (currentLocation) {
      currentLocationId = currentLocation.id;
      initialRegion = currentLocation.region.slug as RegionSlug;
    }

    isSlotTimeVisible = true;
    headerText = i18n.t("Explore other nearby locations");
  }

  const initialRegionSlug = initialRegion ?? "sf-bay-area";

  const isSaas = currentLocation ? isSaasLocation(currentLocation) : false;

  // If saas, show only saas locations, otherwise show only non-saas locations
  const filterCriteria = (location: LocationForLocationExplorerContainer) =>
    isSaas ? location.practiceId === currentLocation?.practiceId : !isSaasLocation(location);

  const locationsToRender = locations.filter(filterCriteria);

  const activeLocations = locationsToRender.filter(l => !l.deactivatedAt);

  const regions = useMemo(() => generateRegionData(locations), [locations]);

  if (!activeLocations.length) return null;

  return (
    <LocationExplorer
      locations={activeLocations}
      headerText={headerText}
      initialRegionSlug={initialRegionSlug}
      // @ts-expect-error TS2322: Type 'string | undefined' is not assignable to type 'string'.
      currentLocationId={currentLocationId}
      isSlotTimeVisible={isSlotTimeVisible}
      isSaas={isSaas}
      regions={regions}
    />
  );
};
