import {useTranslation, useLanguageChanger} from "@i18n/client";
import React, {useState} from "react";
import {languages, selectable} from "src/languages.js";

import {ifChildrenLoseFocus} from "../_common/Carbon";
import OptionsList, {ListOption, SectionHeader} from "./OptionsList";

const positions = {
  "bottom left": {
    top: 42,
    left: 0,
  },
  "bottom right": {
    top: 42,
    right: 0,
  },
  "top left": {
    bottom: 42,
  },
  "top right": {
    bottom: 42,
    right: 0,
  },
};

type Props = React.HTMLAttributes<HTMLDivElement> & {
  anchorDirection?: "bottom left" | "bottom right" | "top left" | "top right";
  onlyShow?: string[];
};

const LanguageSelector = ({
  anchorDirection = "bottom left",
  onlyShow,
  className,
  ...props
}: Props): React.ReactElement => {
  const i18n = useTranslation();
  const changeLanguage = useLanguageChanger();
  const [showList, setShowList] = useState(false);

  const options = languages
    .filter(lng => selectable.includes(lng.code) && (!onlyShow || onlyShow.includes(lng.code)))
    .map<ListOption<string> | SectionHeader>(lng => ({
      text: lng?.native,
      value: lng.code,
      selected: lng.code === i18n.lang,
    }))
    .prepend({header: i18n.t("Select language")});

  const toggleList = (boolean: boolean) => () => {
    setShowList(boolean);
  };

  const listIsOnTop = /top/.test(anchorDirection);

  return (
    <div
      className={`pos-r wfc mr6 focus-bsDarkBlue3 br8 mr0-md ${className || ""}`}
      onBlur={ifChildrenLoseFocus(toggleList(false))}
      {...props}
    >
      <button
        onClick={toggleList(!showList)}
        className="brdn bg-transparent m0 p0 inherit"
        data-cy="language-switcher-footer"
      >
        <div className="br5 p3 hover-bg-gray100 t-150 df aic font-ir fs14 cp">
          <span className="cIcon-globe mr2 fs16" aria-hidden />
          <span className="visually-hidden">{i18n.t("Language: ")}</span>
          <span className="text-sm font-ib">
            {languages.find(lng => i18n.lang === lng.code)?.native}
          </span>
          <span
            className={`cIcon-icon_arrow_dropdown ml2 fs18 t-100 ${
              (!listIsOnTop && showList) || (listIsOnTop && !showList) ? "rotate-180" : ""
            }`}
            aria-hidden
          />
        </div>
      </button>
      {showList && (
        <OptionsList
          className="pos-a bg-white zIndex1 contrast-tb"
          style={{
            ...positions[anchorDirection],
            maxHeight: 600,
            overflowY: "auto",
          }}
          options={options}
          onOutsideClick={toggleList(false)}
          onSelectOption={changeLanguage}
        />
      )}
    </div>
  );
};

export default LanguageSelector;
