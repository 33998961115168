import MenuButton from "@components/components/Menu/MenuButton";
import {AnchorPosition} from "@components/components/Menu/types";
import {ListOption} from "@components/OptionsList";
import {Item} from "@react-stately/collections";
import {useTranslation} from "@i18n/client";
import React, {useCallback, useMemo} from "react";
import {languages, selectable} from "src/languages.js";
import {useLanguageChanger} from "@i18n/client";
import {SupportedLanguage} from "@i18n";

interface NavLanguageSelectorProps {
  renderButton: (
    props: React.ButtonHTMLAttributes<HTMLButtonElement> & {selectedItem: string},
  ) => JSX.Element;
  anchorDirection?: AnchorPosition;
}

export const NavLanguageSelector: React.FC<NavLanguageSelectorProps> = ({
  renderButton,
  anchorDirection = "bottom left",
}) => {
  const {lang, t} = useTranslation();
  const changeLanguage = useLanguageChanger();

  const options = useMemo(
    () =>
      languages
        .filter(lng => selectable.includes(lng.code))
        .map<ListOption<SupportedLanguage>>(lng => ({
          text: lng?.native,
          value: lng.code,
        })),
    [],
  );

  const selected = options.find(({value}) => lang && value === lang.substr(0, 2));
  const selectedKeys = useMemo(() => [selected?.value], [selected?.value]);

  const _renderButton = useCallback(
    (props: React.ButtonHTMLAttributes<HTMLButtonElement>) =>
      renderButton({selectedItem: selected?.text as string, ...props}),
    [renderButton, selected?.text],
  );

  const renderItem = useCallback(
    (item: ListOption<SupportedLanguage>) => (
      <Item key={item.value}>
        <span lang={item.value}>{item.text}</span>
      </Item>
    ),
    [],
  );

  return (
    <MenuButton
      label={t("Select Language")}
      // @ts-expect-error Can't make types specific enough due to ARIA integration
      onAction={changeLanguage}
      // @ts-expect-error Can't make types specific enough due to ARIA integration
      selectedKeys={selectedKeys}
      items={options}
      anchorDirection={anchorDirection}
      renderButton={_renderButton}
    >
      {renderItem}
    </MenuButton>
  );
};
