import LocationDetailsCard, {
  LocationForDetailsCard,
} from "@components/Locations/LocationDetailsCard";
import NextImage from "@components/NextImage";
import {useTranslation} from "@i18n/client";
import React from "react";
import {SoonestSlotTimeByLocationAndSpecialty} from "src/hooks/useGetSoonestTime";

import {I18nNamespace} from "@i18n";
import {s3ImageSource} from "../../../useS3ImgSrc";
import {LocationForGetOpenTime, getOpenTime} from "../../../utils/timeUtils";

export type LocationForExplorerCard = LocationForDetailsCard &
  LocationForGetOpenTime & {
    images?: {
      imageId: string;
    }[];
  };

type Props = {
  location: LocationForExplorerCard;
  soonestSlots: SoonestSlotTimeByLocationAndSpecialty;
};

export const LocationExplorerCard = ({location, soonestSlots}: Props) => {
  const i18n = useTranslation();
  const i18nDB = useTranslation(I18nNamespace.DB);
  const {timeString, timeBlock, isOpenNow, isBeforeOpeningToday, daysFromToday} = getOpenTime(
    i18nDB,
    location,
  );

  const imageId = location.images?.[0].imageId;
  if (!imageId) return null;

  return (
    <li className="df fdc mb8-md">
      <div className="pos-r mb4" style={{height: 250}}>
        <NextImage
          className="br1"
          objectPosition="50% 50%"
          layout="fill"
          src={s3ImageSource(imageId, 2)}
          alt=""
        />
      </div>
      <LocationDetailsCard
        location={location}
        timeBlock={timeBlock}
        timeString={timeString}
        isOpenNow={isOpenNow}
        isBeforeOpeningToday={isBeforeOpeningToday}
        hideButton={false}
        buttonText={i18n.t("View Location")}
        daysFromToday={daysFromToday}
        specialtyBoxMode={false}
        soonestSlots={soonestSlots}
        condensed={false}
      />
    </li>
  );
};
