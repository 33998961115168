import React, {memo} from "react";
import {useTranslation} from "@i18n/client";
import Link from "next/link";
import {v5Pages} from "../../_common/_constants";
import Icon from "@components/Icon";

export const LocationsNearMeBtn = () => {
  const {t} = useTranslation();
  return (
    <Link
      href={v5Pages.locationsNearMe}
      className="mt-4 mr-4 mb-0 ml-8 flex items-center gap-2 !text-gray-1000 hover:font-semibold"
      data-cy="locations-near-me"
    >
      <Icon icon="locArrow" />
      <span>{t("Locations Near Me")}</span>
    </Link>
  );
};

export default memo(LocationsNearMeBtn);
