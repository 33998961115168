import {NavLabel} from "@components/Nav/utils";
import {LinkProps} from "next/link";
import {useTranslation} from "@i18n/client";
import React, {useMemo, useRef} from "react";

import {
  blogURL,
  occupationalHealthLink,
  supportLink,
  v5Pages,
} from "../components/_common/_constants";
import Regions from "@components/Nav/Regions";
import useDrawerRegions, {LocationForDrawer} from "@components/Nav/useDrawerRegions";
import {LocationsNearMeBtn} from "@components/Nav/LocationsNearMeBtn";
import {getNonSaasLocations} from "./locations";
import {RootStateLocation} from "../store/types";

export type DrawerItem = {
  title: string;
  href: LinkProps["href"];
  as?: LinkProps["as"];
  ariaLabel?: string;
  label: NavLabel;
  newTab?: boolean;
  external?: boolean;
  // @ts-expect-error TS7010: 'onClick', which lacks return-type annotation, implicitly has an 'any' return type.
  onClick?();
  count?: number;
  subSubItems?: {title: string; href: LinkProps["href"]; count: number}[];
};

type DrawerGroup = {
  title: string;
  id: string;
  ref: React.MutableRefObject<Element>;
  sub: DrawerItem[];
  label?: NavLabel;
  customHeader?: React.ReactNode;
};

type MoreDropMenuItem = {
  title: string;
  href: LinkProps["href"];
  as?: LinkProps["as"];
  ariaLabel?: string;
  newTab?: boolean;
  external?: boolean;
};

export type DropMenuItem = {
  title: string;
  subTitle: string;
  href: LinkProps["href"];
  image: string;
  as?: LinkProps["as"];
  ariaLabel?: string;
  newTab?: boolean;
  external?: boolean;
};

export type DropDownConfig = {
  id: string;
  title: string;
  ariaLabel: string;
  label: NavLabel;
  menuItems?: DropMenuItem[];
  subTitle?: string;
  secondaryTitle?: string;
  moreMenuItems?: MoreDropMenuItem[];
  customHtml?: React.ReactNode;
  style?: React.CSSProperties;
};

type DropMenuRow = {
  title: string;
  subTitle: string;
  href: string;
  as?: LinkProps["as"];
  ariaLabel?: string;
  newTab?: boolean;
  external?: boolean;
};

export type DropMenuColumn = {
  colTitle: string;
  hideTitle?: true;
  colRows: DropMenuRow[];
};

export type Drawer = {accordion: DrawerGroup[]; footer: DrawerItem[]};

type NavConfig = {
  center: (DrawerItem | DropDownConfig)[];
  drawer: Drawer;
};

export const hasDropDownMenu = (item: DrawerItem | DropDownConfig): item is DropDownConfig =>
  Boolean((item as DropDownConfig).menuItems || (item as DropDownConfig).customHtml);

export const useHeaderConstants = (locations: LocationForDrawer[]): {config: NavConfig} => {
  const i18n = useTranslation();
  const getCareRef = useRef(null);
  const findALocationRef = useRef(null);

  const nonSaasLocations = useMemo(
    () => getNonSaasLocations(locations as RootStateLocation[]),
    [locations],
  );

  const drawerRegions = useDrawerRegions(nonSaasLocations);

  const config: {config: NavConfig} = useMemo(
    () => ({
      config: {
        center: [
          {
            id: "services",
            style: {transform: "translateX(-50%)", left: "45%"},
            title: i18n.t("Get Care"),
            subTitle: i18n.t("Core Services"),
            secondaryTitle: i18n.t("Specialties"),
            ariaLabel: i18n.t(
              "Click to toggle a dropdown menu for Carbon Health's patient services.",
            ),
            label: "Services",
            menuItems: [
              {
                href: v5Pages.urgentCare,
                image: "navigation/get-care/01",
                title: i18n.t("Urgent Care"),
                subTitle: i18n.t("Get care quickly"),
              },
              {
                href: v5Pages.primaryCare,
                image: "navigation/get-care/03",
                title: i18n.t("Primary Care"),
                subTitle: i18n.t("Get preventative care"),
              },
              {
                href: v5Pages.pediatrics,
                image: "navigation/get-care/05",
                title: i18n.t("Pediatric Urgent Care"),
                subTitle: i18n.t("For ages 3 mos - 18 yrs"),
              },
              {
                href: v5Pages.virtualCareMental,
                image: "navigation/get-care/04",
                title: i18n.t("Mental Health"),
                subTitle: i18n.t("Video chat with a provider"),
              },
              {
                href: v5Pages.covidCare,
                image: "navigation/covid-19/01",
                title: i18n.t("COVID Care"),
                subTitle: i18n.t("Testing & treatment"),
              },
              {
                href: v5Pages.virtualCare,
                image: "navigation/get-care/02",
                title: i18n.t("Virtual Care"),
                subTitle: i18n.t("Connect on the go"),
              },
            ],
            moreMenuItems: [
              {
                title: i18n.t("Women's Health"),
                href: v5Pages.womensHealth,
              },
              {
                title: i18n.t("LGBTQ+ Health"),
                href: v5Pages.lgbtq,
              },
              {
                title: i18n.t("Diabetes Care"),
                href: v5Pages.diabetesCare,
                external: true,
              },
            ],
          },
          {
            title: i18n.t("Insurance & Pricing"),
            href: v5Pages.insurance,
            ariaLabel: i18n.t("Click to view detailed insurance and pricing information."),
            label: "Insurances",
          },
          {
            id: "locations",
            style: {transform: "translateX(-50%)", left: "45%"},
            title: i18n.t("Locations"),
            subTitle: i18n.t("Get Care nearby"),
            secondaryTitle: i18n.t("California"),
            ariaLabel: i18n.t("Click to view an interactive map of Carbon Health locations."),
            label: "Locations",
            customHtml: <Regions locations={nonSaasLocations} />,
          },
          {
            id: "company",
            style: {transform: "translateX(-50%)", left: "45%"},
            title: i18n.t("Company"),
            subTitle: i18n.t("Carbon Health"),
            secondaryTitle: i18n.t("for business"),
            ariaLabel: i18n.t("Click to view an interactive map of Carbon Health locations."),
            label: "Company",
            menuItems: [
              {
                href: v5Pages.about,
                image: "navigation/company/01",
                title: i18n.t("About Us"),
                subTitle: i18n.t("Vision, values, & team"),
              },
              {
                href: v5Pages.press,
                image: "navigation/company/05",
                title: i18n.t("Press"),
                subTitle: i18n.t("News & press kit"),
              },
              {
                href: v5Pages.careers,
                image: "navigation/company/03",
                title: i18n.t("Careers"),
                subTitle: i18n.t("We're hiring"),
              },
              {
                href: v5Pages.blog,
                image: "navigation/company/04",
                title: i18n.t("Blog"),
                subTitle: i18n.t("Health & wellness resources"),
              },
            ],
            moreMenuItems: [
              {
                title: i18n.t("Partners"),
                href: v5Pages.partners,
              },
              {
                title: "Carbon for Campus",
                href: v5Pages.carbonForCampus,
              },
              {
                title: i18n.t("Workplace Health"),
                href: occupationalHealthLink,
                external: true,
              },
            ],
          },
        ],
        // @ts-expect-error TS2352, TS2352: Conversion of type '{ accordion: ({ title: string; id: string; ref: React.MutableRefObject<null>; label: "Services"; sub: ({ title: string; href: "/urgent-care"; } | { title: string; href: "/primary-care"; } | ... 4 more ... | { ...; })[]; } | { ...; })[]; footer: ({ ...; } | { ...; })[]; }' to type 'Drawer' may be a mistake because neither type sufficiently overlaps with the other. If this was intentional, convert the expression to 'unknown' first.,  Conversion of type '{ accordion: ({ title: string; id: string; ref: React.MutableRefObject<null>; label: "Services"; sub: ({ title: string; href: "/urgent-care"; } | { title: string; href: "/primary-care"; } | ... 4 more ... | { ...; })[]; } | { ...; })[]; footer: ({ ...; } | { ...; })[]; }' to type 'Drawer' may be a mistake because neither type sufficiently overlaps with the other. If this was intentional, convert the expression to 'unknown' first.
        drawer: {
          accordion: [
            {
              title: i18n.t("Get Care"),
              id: "get-care",
              ref: getCareRef,
              label: "Services",
              sub: [
                {
                  title: i18n.t("Urgent Care"),
                  href: v5Pages.urgentCare,
                },
                {
                  title: i18n.t("Pediatric Urgent Care"),
                  href: v5Pages.pediatrics,
                },
                {
                  title: i18n.t("Primary Care"),
                  href: v5Pages.primaryCare,
                },
                {
                  title: i18n.t("Virtual Care"),
                  href: v5Pages.virtualCare,
                },
                {
                  title: i18n.t("Mental Health Care"),
                  href: v5Pages.virtualCareMental,
                },
                {
                  title: i18n.t("Women's Health"),
                  href: v5Pages.womensHealth,
                },
                {
                  title: i18n.t("LGBTQ+ Health"),
                  href: v5Pages.lgbtq,
                },
                {
                  title: i18n.t("Diabetes Management"),
                  href: v5Pages.diabetesCare,
                  external: true,
                },
                {
                  title: i18n.t("COVID Care"),
                  href: v5Pages.covidCare,
                },
              ],
            },
            {
              title: i18n.t("Find a Location"),
              id: "find-a-location",
              ref: findALocationRef,
              label: "Find a Location",
              customHeader: <LocationsNearMeBtn />,
              sub: drawerRegions,
            },
          ],
          footer: [
            {
              title: i18n.t("Home"),
              href: v5Pages.home,
              ariaLabel: i18n.t("Click to visit the Carbon Health home page."),
            },
            {
              title: i18n.t("Press"),
              href: v5Pages.press,
            },
            {
              title: i18n.t("About Us"),
              href: v5Pages.about,
              ariaLabel: i18n.t("Click to learn more about Carbon Health's leaders and values."),
            },
            {
              title: i18n.t("Partners"),
              href: v5Pages.partners,
            },
            {
              title: i18n.t("Insurance & Pricing"),
              href: v5Pages.insurance,
              ariaLabel: i18n.t("Click to view detailed insurance and pricing information."),
            },
            {
              title: "Carbon for Campus",
              href: v5Pages.carbonForCampus,
            },
            {
              title: i18n.t("Careers"),
              href: v5Pages.careers,
              ariaLabel: i18n.t("Learn more about opportunities to join Carbon Health."),
            },
            {
              title: i18n.t("Workplace Health"),
              href: occupationalHealthLink,
            },
            {
              title: i18n.t("Blog"),
              href: blogURL,
              ariaLabel: i18n.t("Click to visit the Carbon Health blog."),
            },
            {
              title: i18n.t("Support"),
              href: supportLink,
            },
          ],
        } as Drawer,
      },
    }),
    [i18n],
  );

  return config;
};
