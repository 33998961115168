import SplitLayout from "@components/Footer/SplitLayout";
import {useTranslation} from "@i18n/client";
import React from "react";

import {v5Pages} from "../../_common/_constants";
import Image from "next/image";
import coloredLogo from "public/static/img/v5/coloredLogo.svg";
import Button from "@components/Button";
import {useTwMediaQuery} from "../../../hooks/useTwMediaQuery";
import {Breakpoint} from "../../../constants/breakpoint";

const Top: React.VoidFunctionComponent = () => {
  const i18n = useTranslation();
  const isNotSmall = useTwMediaQuery(Breakpoint.SM);

  return (
    <SplitLayout className="aife fdc-sm aifs-sm contrast-tbt pb-6 md:pb-24">
      <div className="dg gafr gg8 gg4-xs fx3 mb5-sm df-sm">
        <div>
          <Image height={50} width={50} src={coloredLogo} alt="Carbon Health" />
        </div>
        <h2 className="fs32 fs24-sm gray800 font-c">
          {i18n.t("All your healthcare,\n in one place.")}
        </h2>
      </div>
      <div className="fx2 pl20-sm pl16-xs">
        <div className="dg aife gg2 gafc">
          <Button size={isNotSmall ? "lg" : "sm"} href={{pathname: v5Pages.download}}>
            {i18n.t("Get the App")}
          </Button>

          <Button size={isNotSmall ? "lg" : "sm"} href={{pathname: v5Pages.locationsNearMe}}>
            {i18n.t("Find a Location")}
          </Button>
        </div>
      </div>
    </SplitLayout>
  );
};

export default Top;
